<template>
  <div class="main">
    <div class="course-add content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Course
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Add new course
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="back">
            <a-button type="primary" ghost v-if="!courseId">
              <router-link to="/course"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
            <a-button type="primary" ghost v-if="courseId">
              <router-link :to="{ name: 'List Sub Course', params: { id: courseId }}"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="body">
        <AddEditCourse />
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons-vue'

import AddEditCourse from '@/components/agency/course/AddEditCourse.vue'

export default defineComponent({
  components: {
    AddEditCourse,
    ArrowLeftOutlined,
    HomeOutlined
  },
  setup () {
    const route = useRoute()
    const courseId = ref<string>(route.params.id as string)
    return {
      courseId
    }
  }
})
</script>

<style lang="scss">

</style>
